<template>
  <div class="rankingList-top">
    <div class="">
      <el-tabs
        v-model="activeName"
        :before-leave="moreState"
        @tab-click="setData"
      >
        <el-tab-pane
          class="tab_title"
          v-for="(item, index) in tabPanList"
          :key="index + 'n'"
          :label="item.label"
          :name="item.name"
        >
        </el-tab-pane>
        <!-- <el-tab-pane name="more" class="more">
          
        </el-tab-pane> -->
        <el-row
          v-for="(park, index) in parkTopList"
          :key="index"
          class="row_style"
        >
          <el-col class="col_style" :class="index % 2 == 0 ? 'double' : ''">
            <span
              class="indexNumber"
              :class="[index + 1 > 3 ? 'bottomNumber' : 'topNumber']"
              >{{ index + 1 }}</span
            >
            <!-- <el-tooltip class="item" effect="dark" v-if="park.parkName.length > 12" :content="park.parkName" placement="top-end">
                 <span class="parkname">{{ park.parkName | parkFilter}}</span>
              </el-tooltip> -->
            <span class="parkname">{{
              park.parkName ? park.parkName : park.areaName | parkFilter
            }}</span>
            <span
              v-show="!percentage"
              class="parkValue"
              :class="[index + 1 > 3 ? 'bottomValue' : 'topValue']"
              >{{
                park.numberValue ? priceFormate(park.numberValue) : park.actualPay ? priceFormate(park.actualPay) : "0.00"
              }}</span
            >
            <span
              v-show="percentage"
              class="parkValue"
              :class="[index + 1 > 3 ? 'bottomValue' : 'topValue']"
              >{{
                park.numberValue ? pricePercentage(park.numberValue) : "0.00%"
              }}</span
            >
          </el-col>
        </el-row>
      </el-tabs>
      <div slot="label" class="moreRight">
        <span
          class="parkLable_top"
          :class="{ isActive: activeStatus === 1 }"
          @click.stop="
            activeStatus === 1 ? (activeStatus = 2) : (activeStatus = 1);
            setSort();
          "
        ></span>
        <span
          class="parkLable_bottom"
          :class="{ isActive: activeStatus === 2 }"
          @click.stop="
            activeStatus === 2 ? (activeStatus = 1) : (activeStatus = 2);
            setSort();
          "
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    parkTopList: {
      default: "",
    },
    tabPanList: {
      default: "",
    },
    activeTWOName: {
      default: "",
    },
  },
  data() {
    return {
      activeName: "1",
      Number: 0,
      activeStatus: "1",
      percentage: false,
      type: 0,
    };
  },
  methods: {
    setSort() {
      if (this.type == 1) {
        this.percentage = true;
      } else {
        this.percentage = false;
      }
      this.$emit("getranking", this.activeName, this.activeStatus);
    },
    setData(n) {
      console.log(n);
      if (n && n.label == "金额实缴率") {
        this.type = 1;
        this.percentage = true;
      } else {
        this.type = 0;
        this.percentage = false;
      }
      this.$emit("getranking", this.activeName, this.activeStatus);
      console.log(this.parkTopList);

      // this.$emit("getranking", this.names1, this.activeStatus);
    },
    moreState(tab) {
      if (tab == "more") {
        return false;
      }
    },
    // 金额满三位显示逗号
    priceFormate(val) {
      if (val == 0) {
        return 0;
      } else if (val <= 9999999) {
        let price = Number(val / 100).toFixed(2);
        price =
          price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") +
          "." +
          price.split(".")[1];
        return price;
      } else {
        // return val / 1000000 + '万'
        let price = Number(val / 1000000).toFixed(2);
        price =
          price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") +
          "." +
          price.split(".")[1] +
          "万";
        return price;
      }
    },
    // 金额率显示
    pricePercentage(val) {
      if (val == 0) {
        return 0;
      } else {
        let price = Number(val * 100).toFixed(2);
        price =
          price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") +
          "." +
          price.split(".")[1];
        return price + "%";
      }
    },
  },
  filters: {
    parkFilter(val) {
      if (val == undefined) {
      } else {
        if (val.length > 12) {
          return val.slice(0, 12) + "...";
        }
        return val;
      }
    },
  },
  watch: {},
  mounted() {
    this.setData();
    console.log(this.parkTopList, this.tabPanList, "parkTopList");
  },
};
// 						background:url(../../assets/img/ranking1.png);
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.rankingList-top {
  position: relative;
  padding: 0 16px 16px;
}

.row_style {
  width: 100%;

  .col_style {
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #E3E4E6;
    padding: 0 16px;
    // background: yellow;
  }

  .double {
    background: #F6F9FC;
  }
}

.indexNumber {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  margin-right: 24px;
}

.topNumber {
  background-color: #F8921C;
  color: white;
}

.bottomNumber {
  color: #606A88;
  background-color: #DFE6FF;
}

.bottomValue, .parkname {
  color: rgba(0, 0, 0, 0.65);
}

.topValue {
  color: rgba(0, 0, 0, 0.9);
}

.parkValue {
  float: right;
  font-size: 14px;
}

.parkLable_top.isActive {
  border-bottom-color: #527BFFFF !important;
}

.parkLable_bottom.isActive {
  border-top-color: #527BFFFF !important;
}

>>>.el-tabs__header {
  margin: 0 0 8px;
}

>>>.el-tabs__item.is-active, >>>.el-tabs__item:hover {
  color: #527BFF;
}

>>>.el-tabs__active-bar {
  background-color: #527BFF;
  padding: 0px 10px;
  margin-left: -10px;
}

>>>#tab-1 {
  padding: 0px 10px;
}

>>>.el-tabs__nav div {
  padding: 0px 10px;
}

>>>.el-tabs__item {
  padding: 0px 20px 0px 0px;
  // height: 32px;
  // line-height: 32px;
  font-size: 12px;
}

>>>.el-tabs__nav-wrap::after {
  height: 1px;
}

>>>.el-tabs__content {
  overflow: auto;
  height: 240px;
}

.rankingList-top >>> .el-tabs__content::-webkit-scrollbar {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
  background: transparent;
  border-radius: 10px;
}

// 滚动条的滑块样式
.rankingList-top >>> .el-tabs__content::-webkit-scrollbar-thumb {
  background-color: rgba(217, 225, 238, 1);
  border-radius: 10px;
}

.moreRight {
  position: absolute;
  height: 40px;
  right: 26px;
  top: 0px;
  cursor: pointer;
}
</style>
